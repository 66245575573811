import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Row } from '../components/helpers';
import Check from '../../src/Icons/Check';
import ChevronLeft from '../../src/Icons/ChevronLeft';
import ChevronRight from '../../src/Icons/ChevronRight';
import MapMarker from '../../src/Icons/MapMarker';
import Minus from '../../src/Icons/Minus';
import Plus from '../../src/Icons/Plus';
import Search from '../../src/Icons/Search';
import Laptop from '../../src/Icons/Laptop';
import QuestionMark from '../../src/Icons/QuestionMark';
import ShoppingCart from '../../src/Icons/ShoppingCart';
import Person from '../../src/Icons/Person';
import People from '../../src/Icons/People';
import HamburgerMenu from '../../src/Icons/HamburgerMenu';
import Lightning from '../../src/Icons/Lightning';
import Pets from '../../src/Icons/Pets';
import Close from '../../src/Icons/Close';
import Zoom from '../../src/Icons/Zoom';
import Spinner from '../../src/Icons/Spinner';
import CircleSolid from '../../src/Icons/CircleSolid';
import ChevronRightCircle from '../../src/Icons/ChevronRightCircle';
import CheckCircle from '../../src/Icons/CheckCircle';
import Ellipses from '../../src/Icons/Ellipses';
import TrashCan from '../../src/Icons/TrashCan';
import Filter from '../../src/Icons/Filter';
import Accessibility from '../../src/Icons/Accessibility';
import Info from '../../src/Icons/Info';
import Nighttime from '../../src/Icons/Nighttime';
import Cart from '../../src/Icons/Cart';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "icons"
    }}>{`Icons`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Icon from 'mfcl/Icons/[Icon Name]'
`}</code></pre>
    <Playground __position={0} __code={'<Row>\n  <Check width={30} height={30} />\n  <ChevronLeft width={30} height={30} />\n  <ChevronRight width={30} height={30} />\n  <MapMarker width={30} height={30} />\n  <Minus width={30} height={30} />\n  <Plus width={30} height={30} />\n  <Search width={30} height={30} />\n  <Laptop width={30} height={30} />\n</Row>\n<br />\n<br />\n<Row>\n  <QuestionMark width={30} height={30} />\n  <ShoppingCart width={30} height={30} />\n  <Person width={30} height={30} />\n  <HamburgerMenu width={30} height={30} />\n  <Lightning width={30} height={30} />\n  <Pets width={30} height={30} />\n  <Close width={30} height={30} />\n  <Spinner width={30} height={30} />\n</Row>\n<br />\n<br />\n<Row>\n  <CircleSolid fillColor=\"#F7D7D5\" width={30} height={30} />\n  <ChevronRightCircle fillColor=\"#D63426\" width={30} height={30} />\n  <CheckCircle fillColor=\"#006601\" width={30} height={30} />\n  <TrashCan stroke=\"#D63426\" width={30} height={30} />\n  <Filter width={30} height={30} />\n  <Ellipses stroke=\"#D63426\" />\n  <Accessibility />\n  <Info width={30} height={30} />\n  <Info width={30} height={30} variant=\"dark\" />\n  <Nighttime width={30} height={30} />\n  <Cart width={30} height={30} fillColor=\"#2D2926\" stroke=\"#2D2926\" />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Row,
      Check,
      ChevronLeft,
      ChevronRight,
      MapMarker,
      Minus,
      Plus,
      Search,
      Laptop,
      QuestionMark,
      ShoppingCart,
      Person,
      People,
      HamburgerMenu,
      Lightning,
      Pets,
      Close,
      Zoom,
      Spinner,
      CircleSolid,
      ChevronRightCircle,
      CheckCircle,
      Ellipses,
      TrashCan,
      Filter,
      Accessibility,
      Info,
      Nighttime,
      Cart,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Check width={30} height={30} mdxType="Check" />
    <ChevronLeft width={30} height={30} mdxType="ChevronLeft" />
    <ChevronRight width={30} height={30} mdxType="ChevronRight" />
    <MapMarker width={30} height={30} mdxType="MapMarker" />
    <Minus width={30} height={30} mdxType="Minus" />
    <Plus width={30} height={30} mdxType="Plus" />
    <Search width={30} height={30} mdxType="Search" />
    <Laptop width={30} height={30} mdxType="Laptop" />
  </Row>
  <br />
  <br />
  <Row mdxType="Row">
    <QuestionMark width={30} height={30} mdxType="QuestionMark" />
    <ShoppingCart width={30} height={30} mdxType="ShoppingCart" />
    <Person width={30} height={30} mdxType="Person" />
    <HamburgerMenu width={30} height={30} mdxType="HamburgerMenu" />
    <Lightning width={30} height={30} mdxType="Lightning" />
    <Pets width={30} height={30} mdxType="Pets" />
    <Close width={30} height={30} mdxType="Close" />
    <Spinner width={30} height={30} mdxType="Spinner" />
  </Row>
  <br />
  <br />
  <Row mdxType="Row">
    <CircleSolid fillColor="#F7D7D5" width={30} height={30} mdxType="CircleSolid" />
    <ChevronRightCircle fillColor="#D63426" width={30} height={30} mdxType="ChevronRightCircle" />
    <CheckCircle fillColor="#006601" width={30} height={30} mdxType="CheckCircle" />
    <TrashCan stroke="#D63426" width={30} height={30} mdxType="TrashCan" />
    <Filter width={30} height={30} mdxType="Filter" />
    <Ellipses stroke="#D63426" mdxType="Ellipses" />
    <Accessibility mdxType="Accessibility" />
    <Info width={30} height={30} mdxType="Info" />
    <Info width={30} height={30} variant="dark" mdxType="Info" />
    <Nighttime width={30} height={30} mdxType="Nighttime" />
    <Cart width={30} height={30} fillColor="#2D2926" stroke="#2D2926" mdxType="Cart" />
  </Row>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      